
  export default {
    name: `ResultsChart`,
    props: {},
    data() {
      return {
        dossierChartValues: [],
        dossierChartPercentages: [],
        verificationChartValues: [],
        verificationChartPercentages: [],
        hasVerificationData: false,
        hasDossierData: false,
        chartOptions: {
          colors: [`#05C73388`, `#FDEA0055`, `#AA000055`],
          dataLabels: {
            style: {
              fontSize: `20px`,
              colors: [`#000000`]
            }
          },
          labels: [],
          legend: {
            position: `bottom`,
            horizontalAlign: `center`,
            fontSize: `20px`,
            font: {
              size: 20
            }
          },
          plotOptions: {
            pie: {
              customScale: 0.7,
              labels: {
                style: {
                  fontSize: `20px`
                }
              }
            }
          }
        }
      }
    },
    async fetch() {
      try {
        const response = await this.$api.dashboard.getChatsResults()

        this.dossierChartValues = response.dossierChartValues
        this.dossierChartPercentages = response.dossierChartPercentages
        const totalDossierValue = this.dossierChartValues.reduce((a, b) => a + b, 0)
        if (totalDossierValue > 0) {
          this.hasDossierData = true;
        }

        this.verificationChartValues = response.verificationChartValues
        const totalVerificationsValue = this.dossierChartValues.reduce((a, b) => a + b, 0)
        if (totalVerificationsValue > 0) {
          this.hasVerificationData = true;
        }
        this.verificationChartPercentages = response.verificationChartPercentages

        this.chartOptions = {
          ...this.chartOptions,
          labels: response.categories
        }
      } catch (error) {
        console.error(`Error fetching chart data:`, error)
      }
    }
  }
